<template>
  <div v-if="skinAnalysis">
    <div class="mt-2 pm-container card p-4">
      <div>
        <router-link class="btn btn-primary" to="/hautberatungen">Zurück</router-link>
      </div>
      <hr>
      <div id="printMe">
        <h4 class="mb-3">Kundeninformationen</h4>

        <div class="card">

          <div class="card-body">
            <div class="row">
              <label class="col-sm-2 col-form-label">Kundenname:</label>
              <div class="col-sm-10 col-form-label">
                {{ skinAnalysis.name }}
              </div>
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label">Email:</label>
              <div class="col-sm-10 col-form-label">
                {{ skinAnalysis.email }}
              </div>
            </div>

            <div class="row" v-if="geoLocation">
              <label class="col-sm-2 col-form-label">Ungefährer Ort:</label>
              <div class="col-sm-10 col-form-label">
                {{ geoLocation.city }} (+/- {{ geoLocation.radius }} km)
              </div>
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label">Angefragt am:</label>
              <div class="col-sm-10 col-form-label">
                {{ $filters.datetime(skinAnalysis.created) }}
              </div>
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label">Beantwortet am:</label>
              <div class="col-sm-10 col-form-label">
                -
              </div>
            </div>
          </div>
        </div>

        <h4 class="mt-5 mb-3">Anfrage</h4>

        <div class="mt-4 card p-2">
          <div v-for="(section, i) in skinAnalysis.schema.sections" v-bind:key="i" class="mt-4">
            <div class="fs-6 fw-bold">Abschnitt {{ (i + 1) }}</div>
            <hr>

            <div v-for="(q, x) in section.questions" v-bind:key="x">

              <div v-if="skinAnalysis.submit.sections[i].answers[x]" class="mb-4 answer">
                <div v-if="skinAnalysis.submit.sections[i].answers[x].answer
            || skinAnalysis.submit.sections[i].answers[x].options?.length > 0
            || skinAnalysis.submit.sections[i].answers[x].customAnswer">
                  <h5 class="fs-6 fw-bold">{{ q.label }}</h5>
                  <div v-if="q.type === 'text'">
                    <p>{{ skinAnalysis.submit.sections[i].answers[x].answer }}</p>
                  </div>

                  <div v-else-if="q.type === 'checkbox'">
                    <ul>
                      <li v-for="(o, i) in skinAnalysis.submit.sections[i].answers[x].options" v-bind:key="i">
                        {{ o }}
                      </li>
                      <li v-if="skinAnalysis.submit.sections[i].answers[x].customAnswer">
                        {{ skinAnalysis.submit.sections[i].answers[x].customAnswer }}
                      </li>
                    </ul>
                  </div>


                  <div v-else-if="q.type === 'radiobutton'">
                    <ul>
                      <li v-for="(o, i) in skinAnalysis.submit.sections[i].answers[x].options" v-bind:key="i">
                        {{ o }}
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div>
        <hr>

        <div class="row mb-1">
          <label class="col-sm-4 col-form-label fw-bold">Kundenname:</label>
          <div class="col-sm-8 col-form-label">
            {{ skinAnalysis.name }}
          </div>
        </div>

        <div class="row mb-1">
          <label class="col-sm-4 col-form-label fw-bold">Email:</label>
          <div class="col-sm-8 col-form-label">
            {{ skinAnalysis.email }}
          </div>
        </div>

        <hr>
<!--        <div  v-if="!skinAnalysis.answerText">-->
<!--          <div class="mb-3">-->
<!--            <label class="form-label">Antwort formulieren:</label>-->
<!--            <textarea v-model="skinAnalysis.answerText" class="form-control" rows="3"></textarea>-->
<!--          </div>-->
<!--          <button @click="answerPerMail" class="btn btn-success btn-sm me-3">Antwort als Email senden</button>-->
<!--        </div>-->
        <hr>
        <div class="mt-2">

          <button @click="markAsProcessed" class="btn btn-primary btn-sm me-2 mt-4">Als beantwortet markieren</button>
<!--          <button @click="addAsCustomer" class="btn btn-primary btn-sm me-2 mt-4">Als Kunde übernehmen</button>-->
          <button @click="print" class="btn btn-primary btn-sm mt-4">Drucken</button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkinAnalysisService from "../../service/skinAnalysis.service";

export default {
  name: "SkinAnalysisEdit",

  data() {
    return {
      id: this.$route.params.id,
      skinAnalysis: null,
      geoLocation: null
    }
  },

  mounted() {
    SkinAnalysisService.get(this.id)
        .then(skinAnalysis => {
          this.skinAnalysis = skinAnalysis.skinAnalysis
          this.geoLocation = skinAnalysis.geoLocation
        });
  },

  computed: {
    locationLink() {
      return `https://www.openstreetmap.org/#map=15/${this.geoLocation.latitude}/${this.geoLocation.longitude}`;
    },

    locationSrc() {
      return `https://www.openstreetmap.org/export/embed.html?bbox=${this.geoLocation.longitude}%2C${this.geoLocation.latitude}%2C${this.geoLocation.longitude}%2C${this.geoLocation.latitude}&amp;layer=mapnik`
    }
  },

  methods: {
    markAsProcessed() {
      SkinAnalysisService.markAsProcessed(this.id).then(() => {
        this.$router.push("/hautberatungen")
      })
    },

    answerPerMail() {
      SkinAnalysisService.answerPerMail(this.id, this.skinAnalysis.answerText).then(() => {
        this.$router.push("/hautberatungen")
      })
    },

    print() {
      this.$htmlToPaper('printMe')
    },

    addAsCustomer() {

    }
  }
}
</script>

<style scoped>

</style>